import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SORT } from '../../constants';
import PermissionWrapper from '../permissionWrapper';

function SortableTable({ columns: propsColumns, rows: propsRows, translationPath, teamId }) {
    const { t } = useTranslation();
    const [rows, setRows] = useState(propsRows);
    const [columns, setColumns] = useState(propsColumns.map(column => (column?.sortable ? { ...column, sort: SORT.ASC } : column)));

    useEffect(() => {
        setRows(propsRows);
    }, [propsRows]);

    const sortByColumn = (clickedColumn) => {
        setRows(prevState => prevState.sort((a, b) => {
            if (clickedColumn.sort === SORT.ASC) {
                if (clickedColumn?.type === 'date') {
                    return (new Date(a[clickedColumn.key])) >= (new Date(b[clickedColumn.key])) ? 1 : -1;
                }

                return (a[clickedColumn.key].toLowerCase() >= b[clickedColumn.key].toLowerCase()) ? 1 : -1;
            }
            if (clickedColumn?.type === 'date') {
                return (new Date(b[clickedColumn.key])) >= (new Date(a[clickedColumn.key])) ? 1 : -1;
            }
            return (a[clickedColumn.key].toLowerCase() <= b[clickedColumn.key].toLowerCase()) ? 1 : -1;
        }));

        setColumns(prevState => prevState.map((column) => {
            if (column.key === clickedColumn.key) {
                return { ...column, sort: column.sort === SORT.ASC ? SORT.DESC : SORT.ASC };
            }
            return column?.sort ? { ...column, sort: SORT.ASC } : column;
        }));
    };

    return (
        <Table bordered hover size="lg" className="sortableTable mt-2 mb-5">
            <thead className="table-header">
                <tr>
                    {columns?.map((column) => (
                        <PermissionWrapper
                            key={column.key}
                            allowed={column?.restrictedAccess && [column.restrictedAccess]}
                            teamId={teamId}
                        >
                            <th className={`${column?.icon ? 'text-center' : ''}`}>
                                <span
                                    className={`${column?.icon ?
                                        'text-center' :
                                        'd-flex justify-content-between flex-no-wrap align-items-center'
                                    }`}
                                >
                                    <span>{t(`${translationPath}.${column.key}`)}</span>
                                    {column?.sort && (
                                        <span>
                                            <i
                                                className={column.sort === SORT.ASC ?
                                                    'bi bi-chevron-down sort-icon' : 'bi bi-chevron-up sort-icon'}
                                                onClick={() => sortByColumn(column)}
                                            />
                                        </span>
                                    )}
                                    {column?.headerSuffix && <span>{column?.headerSuffix}</span>}
                                </span>
                            </th>
                        </PermissionWrapper>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows?.map((row, i) => (
                    <tr key={i}>
                        {columns?.map(column => (
                            <PermissionWrapper
                                key={`${i}-${column.key}`}
                                allowed={column?.restrictedAccess && [column.restrictedAccess]}
                                teamId={teamId}
                            >
                                <td key={`${i}-${column.key}`} className={column?.icon ? 'text-center' : ''}>
                                    {column?.icon && (
                                        column?.disabledKey && row[column.disabledKey] ?
                                            <span>{column?.disabledMessage || ''}</span> :
                                            <i
                                                disabled={column?.disabledKey && row[column.disabledKey]}
                                                className={`${column?.icon} m-2 cursor visible-icon`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    column.onClick(row, e);
                                                }}
                                            />)}
                                    {column?.link && (
                                        <Link
                                            className="link shadow-none"
                                            to={`${column.link}?teamId=${row?._id}`}
                                        >
                                            <h6>{row?.name}</h6>
                                        </Link>
                                    )}
                                    {!column?.icon && !column?.link && (
                                        <span>
                                            {column?.formatDisplay ? column?.formatDisplay(row[column.key]) : row[column.key]}
                                        </span>
                                    )}
                                    {row[column?.suffixKey] && <span>{column?.suffix}</span>}
                                </td>
                            </PermissionWrapper>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default SortableTable;
