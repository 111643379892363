import { useTranslation } from 'react-i18next';
import { logout } from '../features/agendaSlice';
import { store } from '../store';
import { ROLES } from '../constants';

function PermissionWrapper({ allowed, teamId, children, displayNoAccessMessage, fallbackComponent }) {
    const { t } = useTranslation();
    const userString = localStorage.getItem('user');
    if (userString === 'undefined') {
        store.dispatch(logout());
    }

    if (!allowed?.length) {
        return children;
    }

    const user = userString ? JSON.parse(userString) : {};
    const userRole = user?.role;
    let userPermissions = userRole?.permissions;

    if (teamId && ![ROLES.superAdmin.name].includes(userRole.name)) { // ROLES.companyAdmin.name
        user?.memberships?.some((membership) => {
            const membershipTeamId = membership?.team?._id?.toString() || membership?.teamId?.toString();
            if (membershipTeamId === teamId?.toString()) {
                userPermissions = membership?.role?.permissions ?? userPermissions;
                return true;
            }
            return false;
        });
    }

    const filteredPermissions = userPermissions?.filter(value => allowed?.includes(value));
    if (filteredPermissions?.length) {
        return children;
    }

    if (displayNoAccessMessage) {
        return <h4 className="m-5">{t('error_message.you_have_no_access')}</h4>;
    }

    return fallbackComponent || null;
}

export default PermissionWrapper;
