import { useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { showToastErrorMessage, showToastMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import { ROLES, ROLES_PERMISSIONS } from '../../../constants';
import { axios } from '../../../helpers/apiHelper';
import EditUser from './editUserModal';
import PermissionWrapper from '../../permissionWrapper';
import InviteUsers from './inviteUsers';
import SortableTable from '../sortableTable';
import CustomModal from '../../dashboard/modalCustom';
import { fetchUser } from '../../../features/agendaSlice';

function UsersAdministration() {
    const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
    const [usersInCurrentlySelectedTeam, setUsersInCurrentlySelectedTeam] = useState();
    const [allUsersFromLoggedUserTeams, setAllUsersFromLoggedUserTeams] = useState();
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showAreYouSureYouWantToDeleteUser, setShowAreYouSureYouWantToDeleteUserModal] = useState(false);
    const [
        showAreYouSureYouWantToRemoveUserFromTeam,
        setShowAreYouSureYouWantToRemoveUserFromTeamModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState();
    const [userToDelete, setUserToDelete] = useState();
    const [currentlySelectedTeam, setCurrentlySelectedTeam] = useState();
    const [userToRemove, setUserToRemove] = useState();
    const [userTeams, setUserTeams] = useState();
    const [rolesByIds, setRolesByIds] = useState();
    const [rolesByKeys, setRolesByKeys] = useState();
    const [usersFetched, setUsersFetched] = useState(false);
    const [updateOfUsersInTeamMade, setUpdateOfUsersInTeamMade] = useState();
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const { userData: loggedUser } = useSelector(state => state.agenda);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const teamIdFromUrl = new URLSearchParams(location.search).get('teamId');

    const validationSchema = Yup.object().shape({
        team: Yup.string().required(t('error_message.required_field'))
    });
    const {
        register,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const { onChange: onChangeTeamSelect, ...teamSelectProps } = register('team');

    const openEditUserModal = (user) => {
        setShowEditUserModal(true);
        setUserToEdit(JSON.parse(JSON.stringify(user)));
    };

    const toggleAreYouSureYouWantToDeleteUserModal = () => {
        setShowAreYouSureYouWantToDeleteUserModal(prevState => !prevState);
    };

    const toggleAreYouSureYouWantToRemoveUserFromTeamModal = () => {
        setShowAreYouSureYouWantToRemoveUserFromTeamModal(prevState => !prevState);
    };

    const openAreYouSureYouWantToDeleteUserModal = (user) => {
        toggleAreYouSureYouWantToDeleteUserModal();
        setUserToDelete(user);
    };

    const openAreYouSureYouWantToRemoveUserFromTeamModal = (user) => {
        toggleAreYouSureYouWantToRemoveUserFromTeamModal();
        setUserToRemove(user);
    };

    const softDeleteUser = async () => {
        try {
            await axios.delete(`user/${userToDelete?._id}/anonymize`, { data: { team: getValues('team') } });
            setUpdateOfUsersInTeamMade(true);
            toggleAreYouSureYouWantToDeleteUserModal();
            dispatch(showToastSuccessMessage(t('success_message.administration.user_successfully_deleted')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.deleting_user_failed')));
        }
    };

    const usersTableColumns = [
        { key: 'name',
            sortable: true,
            suffix: (
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            {t('administration.team_lead')}
                        </Tooltip>
                    }
                >
                    <i className="bi bi-person-check-fill ms-1"/>
                </OverlayTrigger>
            ),
            suffixKey: 'isTeamLead'
        },
        { key: 'email', sortable: true },
        { key: 'registered', sortable: true },
        { key: 'user_type', sortable: true },
        // clickable columns can be disabled for some rows - disabledKey is used for that - row[column.disabledKey] returns true
        // if it's disabled and in that case the disabledMessage will be displayed in cases of icon fields
        {
            key: 'edit',
            icon: 'bi bi-pencil-square',
            restrictedAccess: ROLES_PERMISSIONS.USER_WRITE,
            disabledKey: 'disabledEditing',
            onClick: openEditUserModal,
            headerSuffix: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t('administration.cant_edit_user_details')}
                        </Tooltip>
                    }
                >
                    <i className="bi bi-info-square ms-1"/>
                </OverlayTrigger>
            )
        },
        {
            key: 'delete',
            icon: 'bi bi-trash',
            disabledKey: 'disabledDeleting',
            restrictedAccess: ROLES_PERMISSIONS.USER_WRITE,
            onClick: openAreYouSureYouWantToDeleteUserModal,
            headerSuffix: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t('administration.cant_delete_user_details')}
                        </Tooltip>
                    }
                >
                    <i className="bi bi-info-square ms-1"/>
                </OverlayTrigger>
            )
        },
        {
            key: 'remove',
            icon: 'bi bi-x-square',
            disabledKey: 'disabledRemoving',
            restrictedAccess: ROLES_PERMISSIONS.USER_WRITE,
            onClick: openAreYouSureYouWantToRemoveUserFromTeamModal,
            headerSuffix: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t('administration.cant_remove_user_details')}
                        </Tooltip>
                    }
                >
                    <i className="bi bi-info-square ms-1"/>
                </OverlayTrigger>
            )
        }
    ];

    useEffect(() => {
        (async () => {
            try {
                const rolesRes = await axios.get('/role');
                setRolesByIds(rolesRes?.data.reduce((acc, role) => ({
                    ...acc,
                    [role._id]: role
                }), {}));
                setRolesByKeys(rolesRes?.data.reduce((acc, role) => ({
                    ...acc,
                    [ROLES[role?.name]?.key]: role
                }), []));
            } catch (err) {
                dispatch(showToastErrorMessage(t('error_message.global_message')));
            }
        })();
    }, []);

    const filterUsers = (teamId) => {
        if (allUsersFromLoggedUserTeams) {
            const allUsersFromCurrentlySelectedTeam = allUsersFromLoggedUserTeams.filter((user) => (
                (user.memberships?.some((membership) => membership.teamId.toString() === teamId.toString() &&
                        membership.isActive === true) &&
                        !user.teamUserIsInvitedTo
                ) || (user.teamUserIsInvitedTo && user.teamUserIsInvitedTo.toString() === teamId.toString())
            ));
            setUsersInCurrentlySelectedTeam(allUsersFromCurrentlySelectedTeam.map(user => {
                const membershipForTeam = user?.memberships?.find(membership => {
                    const membershipTeamId = membership?.teamId?.toString();
                    return membershipTeamId === teamId.toString();
                });

                let userRole = loggedUser?.role;
                if (userRole?.name !== ROLES.superAdmin.name) {
                    const loggedUserMembershipForTeam = loggedUser?.memberships?.find((membership) => {
                        const membershipTeamId = membership?.team?._id?.toString() || membership?.teamId?.toString();
                        return membershipTeamId === teamId.toString();
                    });

                    if (loggedUserMembershipForTeam?.role) {
                        userRole = loggedUserMembershipForTeam.role;
                    }
                }

                setSelectedTeamId(teamId);
                const isUserInTheTeam = user.memberships?.teamId || user.memberships?.team?._id;
                const registered = membershipForTeam?.isActive ? t('administration.registered') : t('administration.pending');
                const isUsersStatusHigher = ROLES[userRole.name]?.level < ROLES[membershipForTeam?.role?.name]?.level;
                const isUserSuperAdmin = membershipForTeam?.role?.name === ROLES.superAdmin.name;
                const isUserCompanyAdmin = membershipForTeam?.role?.name === ROLES.companyAdmin.name;
                const isTeamAdmin = membershipForTeam?.role?.name === ROLES.teamAdmin.name;
                const isUser = membershipForTeam?.role?.name === ROLES.user.name;
                let userRoleInCurrentlySelectedTeam;

                if (isUserSuperAdmin) {
                    userRoleInCurrentlySelectedTeam = ROLES.superAdmin.key;
                }
                if (isUserCompanyAdmin) {
                    userRoleInCurrentlySelectedTeam = ROLES.companyAdmin.key;
                }
                if (isTeamAdmin) {
                    userRoleInCurrentlySelectedTeam = ROLES.teamAdmin.key;
                }
                if (isUser) {
                    userRoleInCurrentlySelectedTeam = ROLES.user.key;
                }

                return (
                    {
                        ...user,
                        name: user?.firstName ? `${user?.firstName || ''} ${user?.lastName || ''}` :
                            t('administration.not_registered'),
                        team: teamId,
                        user_type: t(`role.${userRoleInCurrentlySelectedTeam}`),
                        registered: isUserInTheTeam && !user?.isActive ? t('administration.deleted') : registered,
                        disabledEditing: isUsersStatusHigher || !user?.isActive,
                        disabledDeleting: isUsersStatusHigher || user?._id === loggedUser?._id || !user?.isActive,
                        disabledRemoving: isUsersStatusHigher || user?._id === loggedUser?._id,
                        isTeamLead: user?.teamsThatUserIsTeamLeadOf?.includes(teamId),
                        role: rolesByKeys[userRoleInCurrentlySelectedTeam]
                    }
                );
            }));
        }
    };

    const fetchUsersInTeam = async () => {
        if (loggedUser?.lastSelectedTeam?._id) {
            setUsersFetched(false);
            try {
                const fetchedUsersFromLoggedUserTeam = await axios.get('/user/withInvitations');
                setAllUsersFromLoggedUserTeams(fetchedUsersFromLoggedUserTeam.data);
                filterUsers(loggedUser?.lastSelectedTeam?._id);
                setUsersFetched(true);
            } catch (error) {
                setUsersFetched(true);
                dispatch(showToastErrorMessage(t('error_message.administration.failed_team_users_loading')));
            }
        } else {
            setUsersInCurrentlySelectedTeam([]);
            setAllUsersFromLoggedUserTeams([]);
            setUsersFetched(true);
        }
    };

    useEffect(() => {
        if (rolesByIds || updateOfUsersInTeamMade) {
            (async () => {
                await fetchUsersInTeam();
                setUpdateOfUsersInTeamMade(false);
            })();
        }
    }, [rolesByIds, updateOfUsersInTeamMade]);

    const toggleInviteUsersModal = () => {
        setShowInviteUsersModal(prevState => !prevState);
    };

    const closeEditUserModal = () => {
        setShowEditUserModal(prevState => !prevState);
        setUserToEdit();
    };

    const removeUserFromTheTeam = async () => {
        const currentlySelectedTeamFromValues = getValues('team');
        try {
            if (currentlySelectedTeamFromValues) {
                await axios.put(`user/${userToRemove?._id}/remove`, { team: currentlySelectedTeamFromValues });
                setUpdateOfUsersInTeamMade(true);
                setUserToRemove();
                toggleAreYouSureYouWantToRemoveUserFromTeamModal();
                dispatch(showToastSuccessMessage('Successfully removed user'));
            }
        } catch (err) {
            dispatch(showToastErrorMessage('error while removing user'));
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data: loggedUserData } = await axios.get(`/user/${loggedUser?._id}`);
                setUserTeams(loggedUserData?.teams.reduce((acc, team) => ({
                    ...acc,
                    [team._id]: team
                }), {}));
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.administration.failed_users_teams_loading')));
            }
        })();
    }, [loggedUser?.lastSelectedTeam, updateOfUsersInTeamMade]);

    useEffect(() => {
        if (!allUsersFromLoggedUserTeams && !userTeams) {
            return;
        }
        const teamId = currentlySelectedTeam || teamIdFromUrl || loggedUser.lastSelectedTeam?._id || '';

        filterUsers(teamId);
        setValue('team', teamId);
    }, [loggedUser?.lastSelectedTeam, allUsersFromLoggedUserTeams, userTeams]);

    const saveUpdatedUser = async (isDirty, editedUser) => {
        if (isDirty) {
            try {
                await axios.put(`/user/${userToEdit._id}`, {
                    userData: {
                        ...editedUser,
                        phone: editedUser?.phone || undefined,
                        teamId: userToEdit.team
                    }
                });

                if (userToEdit._id === loggedUser?._id) {
                    await dispatch(fetchUser());
                }

                setUpdateOfUsersInTeamMade(true);

                closeEditUserModal();
                dispatch(showToastSuccessMessage(t('success_message.administration.successfully_updated_user')));
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.administration.failed_updating_user')));
            }
        } else {
            dispatch(showToastMessage({ type: 'warning', message: t('warning_message.no_changes_made') }));
        }
    };

    return (
        <div className="scrollableWrapper h-100">
            {usersFetched && !loggedUser?.lastSelectedTeam?._id ? <h5 className="m-5">{t('administration.no_team_selected')}</h5> : (
                <div className="usersAdministration m-5 h-100">
                    <div className="mt-5">
                        <h1>{t('administration.users_administration')}</h1>
                    </div>
                    <PermissionWrapper
                        teamId={getValues('team')}
                        allowed={[ROLES_PERMISSIONS.INVITE_WRITE]}
                    >
                        <Button variant="link" className="shadow-none my-3 p-0 link-to-invite fs-5" onClick={toggleInviteUsersModal}>
                            <span className="pe-2">{t('administration.invite_users')}</span>
                            <i className="bi bi-plus-square cursor align-middle"/>
                        </Button>
                    </PermissionWrapper>
                    {showInviteUsersModal && (
                        <InviteUsers
                            closeModal={toggleInviteUsersModal}
                            setInvitationSent={setUpdateOfUsersInTeamMade}
                            teamToInviteTo={userTeams[currentlySelectedTeam]}
                        />
                    )}
                    <Form.Group className="mb-3">
                        <Form.Select
                            className="w-25 form-control mb-3 shadow-none border-color-primary"
                            isInvalid={errors?.team}
                            placeholder={t('administration.teams_table.companyName')}
                            name="team"
                            defaultValue={teamIdFromUrl || loggedUser?.lastSelectedTeam?._id}
                            onChange={(e) => {
                                onChangeTeamSelect(e);
                                filterUsers(e.target.value);
                                setCurrentlySelectedTeam(e.target.value);
                            }}
                            {...teamSelectProps}
                        >
                            <option key="000" value="">{t('best_contribution.please_select')}</option>
                            {loggedUser.teams?.map((team) => (
                                <option
                                    value={team?._id}
                                    key={team?._id}
                                >
                                    {team?.name}
                                </option>
                            ))}
                        </Form.Select>
                        {errors?.team && <Form.Label className="error-placeholder">{errors.team?.message}</Form.Label>}
                    </Form.Group>
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.USER_READ]}>
                        <div className="d-flex flex-column align-items-start my-4 pb-5">
                            <h3>{t('administration.users_table_headline')}</h3>
                            {usersFetched && usersInCurrentlySelectedTeam?.length > 0 &&
                                <SortableTable
                                    rows={usersInCurrentlySelectedTeam}
                                    columns={usersTableColumns}
                                    translationPath="administration.users_table"
                                    teamId={getValues('team')}
                                />
                            }
                            {usersFetched && usersInCurrentlySelectedTeam?.length === 0 && <p>{t('administration.no_users_in_team')}</p>}
                            {!usersFetched && <Spinner animation="border"/>}
                        </div>
                    </PermissionWrapper>
                    {showEditUserModal &&
                        <EditUser
                            roles={Object.values(rolesByIds)}
                            closeModal={closeEditUserModal}
                            user={userToEdit}
                            editUser={saveUpdatedUser}
                            selectedTeamId={selectedTeamId}
                        />
                    }
                    {showAreYouSureYouWantToDeleteUser && (
                        <CustomModal
                            title={`${t('administration.delete_user')} ${userToDelete?.firstName} ${userToDelete?.lastName}`}
                            text1={t('administration.are_you_sure_you_want_to_delete_user')}
                            warningText={t('administration.user_will_be_anonymized')}
                            secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                            primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                            onPrimaryButtonClick={softDeleteUser}
                            onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteUserModal}
                        />
                    )}
                    {showAreYouSureYouWantToRemoveUserFromTeam && (
                        <CustomModal
                            title={`${t('administration.remove_user')} ${userToRemove?.firstName || ''} ${userToRemove?.lastName || ''}`}
                            text1={t('administration.are_you_sure_you_want_to_remove_user')}
                            secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                            primaryButtonLabel={t('administration.users_table.remove')}
                            onPrimaryButtonClick={removeUserFromTheTeam}
                            onSecondaryButtonClick={toggleAreYouSureYouWantToRemoveUserFromTeamModal}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default UsersAdministration;
